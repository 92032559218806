import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Language_Selection/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Language Selection",
  "path": "/Quick_Installation/Language_Selection/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "How to set the Language in the Web User Interface?",
  "image": "./QI_SearchThumb_LanguageSettings.png",
  "social": "/images/Search/QI_SearchThumb_LanguageSettings.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Language_Selection_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Language Selection' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to set the Language in the Web User Interface?' image='/images/Search/QI_SearchThumb_LanguageSettings.png' twitter='/images/Search/QI_SearchThumb_LanguageSettings.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Sprachauswahl/' locationFR='/fr/Quick_Installation/Language_Selection/' crumbLabel="Language" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "how-to-set-the-language-in-the-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-set-the-language-in-the-web-user-interface",
        "aria-label": "how to set the language in the web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to set the Language in the Web User Interface?`}</h2>
    <p>{`The new INSTAR web interface for your IP camera detects automatically what default language your web browser is using. By default, the web interface will be loaded in the language which is used by your web browser. You can also click `}<Link to="/Web_User_Interface/1080p_Series/System/Language/" mdxType="Link">{`System / Language`}</Link>{` for `}<strong parentName="p">{`1080p cameras`}</strong>{`, or `}<Link to="/Web_User_Interface/720p_Series/Software/Language/" mdxType="Link">{`Software / Language`}</Link>{` on the left side navigation of your `}<strong parentName="p">{`720p camera`}</strong>{`, in order to select the language, you would like to use.`}</p>
    <h3 {...{
      "id": "1080p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1080p-cameras",
        "aria-label": "1080p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/843b3f0b06f1436fb9c1c310c5359ede/0d6fe/Spracheinstellung_fullHD_EN1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAChUlEQVQoz2MoKSkpLy8vryivKCspbO5Nn7ElfdrGtKkb4ieui5uwFoJiJ6xLmLQ+Y/qW0kmLqivL/fz89fX1LSwsGErLIForS4oKJy5Yse/xz113P+18+Gv7w787n/zb+RiEdj38eejhl7Pv/09asNrP3c3F1VVLS1tPX5+huLSitKyisrKqqKho3do1////+/fr6/8HB//f3PT/8b5/j/f+f7rn6/t7Z59+uf/9/5Jp08TYWIWEhRWVVZVV1UA2l5aXV1WBNK9es/r///9//vz99eLqz4cnfzw69fn+yY/3jr95/vDsndf33v5cOGsOGwODuqaGrb2DkooaQ0lpeUlpeUVlZVFR0Zo1a/6DrP4HIv+DwPV3fzc8+H3m2rMvy1f833dg5dx5UsIirq7Oru7ucgpKDCUgmysqKirLysq6u7t6enoz0zLKKyurK8rri0sr8wuqi4v7J06c2N1Tm5EZFBikLiZmbWnm6uGuoKjMUFZeUVJWDtJeUVFTXe3r4yctJatjaKivrWOsoGigoKAjJubv4uLj6qLDxa0kJaWjomphZuLm4a6kos5QUVFZUlZRVlZRXl5eV1cXERqmq6tnYGhooK9vqatjqqlhoCDvamrsam/jJCdvrqGuIyGpb2BoYWmtrKbJUF4BCu2ysvIysObQgEA1NU1rezsrW2vvID9HVyd1BfnA4EAfXy99KSkLfV1jTR1VTV1JGTkJGTmQzaXl5WVgd9fU1ibFx7u7efgHBweHhcYkxAcGBdva2jm7urp7etrY2Nja2Nra2Ts4udg5ONrY2oFsLgFZW1FZWVlRUdHQ0NDa2trS0tLU2NTU1NTY2NQCA21tra0QuZYWMKsVAOr5L9ie5lPGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/843b3f0b06f1436fb9c1c310c5359ede/e4706/Spracheinstellung_fullHD_EN1.avif 230w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/d1af7/Spracheinstellung_fullHD_EN1.avif 460w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/7f308/Spracheinstellung_fullHD_EN1.avif 920w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/eec0b/Spracheinstellung_fullHD_EN1.avif 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/843b3f0b06f1436fb9c1c310c5359ede/a0b58/Spracheinstellung_fullHD_EN1.webp 230w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/bc10c/Spracheinstellung_fullHD_EN1.webp 460w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/966d8/Spracheinstellung_fullHD_EN1.webp 920w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/177a2/Spracheinstellung_fullHD_EN1.webp 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/843b3f0b06f1436fb9c1c310c5359ede/81c8e/Spracheinstellung_fullHD_EN1.png 230w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/08a84/Spracheinstellung_fullHD_EN1.png 460w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/c0255/Spracheinstellung_fullHD_EN1.png 920w", "/en/static/843b3f0b06f1436fb9c1c310c5359ede/0d6fe/Spracheinstellung_fullHD_EN1.png 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/843b3f0b06f1436fb9c1c310c5359ede/c0255/Spracheinstellung_fullHD_EN1.png",
              "alt": "1080p WebUI Language Selection",
              "title": "1080p WebUI Language Selection",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to `}<Link to="/Web_User_Interface/1080p_Series/System/Language/" mdxType="Link">{`System / Language`}</Link>{` and choose the language you want to load the user interface in.`}</p>
    <h3 {...{
      "id": "720p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#720p-cameras",
        "aria-label": "720p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b69de0f8e14fa48dde13a4ca195910d/29d31/Spracheinstellung_HD_EN1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABXNazVKhH/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIDAREhMTP/2gAIAQEAAQUCkwySsXoT9F2f/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAAhAAARIh/9oACAEBAAY/Aimb6jf/xAAcEAADAAEFAAAAAAAAAAAAAAAAARFBITFhcbH/2gAIAQEAAT8htKtdGgjWcnGjwG0Vn//aAAwDAQACAAMAAAAQUy//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAHRABAAICAgMAAAAAAAAAAAAAAQARIVExYbHB0f/aAAgBAQABPxBjwlVZxgiCg4DwPpCr1xpVvwItfOumd0//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b69de0f8e14fa48dde13a4ca195910d/e4706/Spracheinstellung_HD_EN1.avif 230w", "/en/static/9b69de0f8e14fa48dde13a4ca195910d/d1af7/Spracheinstellung_HD_EN1.avif 460w", "/en/static/9b69de0f8e14fa48dde13a4ca195910d/70e80/Spracheinstellung_HD_EN1.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b69de0f8e14fa48dde13a4ca195910d/a0b58/Spracheinstellung_HD_EN1.webp 230w", "/en/static/9b69de0f8e14fa48dde13a4ca195910d/bc10c/Spracheinstellung_HD_EN1.webp 460w", "/en/static/9b69de0f8e14fa48dde13a4ca195910d/426ac/Spracheinstellung_HD_EN1.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b69de0f8e14fa48dde13a4ca195910d/e83b4/Spracheinstellung_HD_EN1.jpg 230w", "/en/static/9b69de0f8e14fa48dde13a4ca195910d/e41a8/Spracheinstellung_HD_EN1.jpg 460w", "/en/static/9b69de0f8e14fa48dde13a4ca195910d/29d31/Spracheinstellung_HD_EN1.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b69de0f8e14fa48dde13a4ca195910d/29d31/Spracheinstellung_HD_EN1.jpg",
              "alt": "720p WebUI Language Selection",
              "title": "720p WebUI Language Selection",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to `}<Link to="/Web_User_Interface/720p_Series/Software/Language/" mdxType="Link">{`Software / Language`}</Link>{` `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5101d43bc46485816306ca1129f537e5/29d31/Spracheinstellung_HD_EN2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABg1ShpKSP/8QAHBAAAQMFAAAAAAAAAAAAAAAAAQACAxARITIz/9oACAEBAAEFAh1nxEn7XNP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAABEAAxcf/aAAgBAQAGPwIazLX/xAAaEAADAQADAAAAAAAAAAAAAAAAARExYXGB/9oACAEBAAE/Ic3AWnaKhpITtZ+lP//aAAwDAQACAAMAAAAQMC//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAwEAAwAAAAAAAAAAAAABABExQVFhgf/aAAgBAQABPxC3IZmaQk1ywOz1kZKpEpPkTAa8Uy3mf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5101d43bc46485816306ca1129f537e5/e4706/Spracheinstellung_HD_EN2.avif 230w", "/en/static/5101d43bc46485816306ca1129f537e5/d1af7/Spracheinstellung_HD_EN2.avif 460w", "/en/static/5101d43bc46485816306ca1129f537e5/70e80/Spracheinstellung_HD_EN2.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5101d43bc46485816306ca1129f537e5/a0b58/Spracheinstellung_HD_EN2.webp 230w", "/en/static/5101d43bc46485816306ca1129f537e5/bc10c/Spracheinstellung_HD_EN2.webp 460w", "/en/static/5101d43bc46485816306ca1129f537e5/426ac/Spracheinstellung_HD_EN2.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5101d43bc46485816306ca1129f537e5/e83b4/Spracheinstellung_HD_EN2.jpg 230w", "/en/static/5101d43bc46485816306ca1129f537e5/e41a8/Spracheinstellung_HD_EN2.jpg 460w", "/en/static/5101d43bc46485816306ca1129f537e5/29d31/Spracheinstellung_HD_EN2.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5101d43bc46485816306ca1129f537e5/29d31/Spracheinstellung_HD_EN2.jpg",
              "alt": "720p WebUI Language Selection",
              "title": "720p WebUI Language Selection",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`... and choose the language you want to load the user interface in.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      